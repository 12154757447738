

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './testimonials.css'
import img1 from '../../assets/img1.jpg'
import img2 from '../../assets/img2.jpg'
import test1 from '../../assets/test1.jpeg'
import link2 from '../../assets/link2.jpg'

// import Swiper core and required modules
import {  Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: img1,
    name: 'Yaro Jordan Haziel',
    review: 'Yaro Jordan Haziel is the Founder/Owner of Jordan football Academy based in abia state. The founder has passion for sports, especially football and seeing young talents pursue their dreams. The academy aim is to bring young talents from across the country to showcase their talent. Provide an environment in which they can combine their education with their passion for football and be the best they can be in achieving their full potentials.'
  },

  {
    avatar: test1,
    name: 'YARO JENNIFER HAZIEL',
    review: 'Yaro Jennifer Haziel is a member of the Board of Directors at JORDAN FOOTBALL ACADEMY. She is a graduate of Law and a native of Adamawa State. She is a football enthusiast with an unequivocal drive to carving a niche for herself in football administration and talent development. This she aims to achieve by being a part of the driving wheel of the Jordan Football Academy project.'
  },
  {
    avatar: link2,
    name: 'SALMA LENNZEL TOMBOCHIM',
    review: 'Salma Lennzel Tombochim is head legal department for the academy. She was called to the Nigerian Bar as Advocate and Solicitor on 16 th December, 2015 and currently a partner at House of Niya Enterprises.She has served in different capacities in the field of Research as it relates to Women’s Reproductive Health rights in Nigeria with over 5 years experience.'
  },
  {
    avatar: link2,
    name: 'EZENWOKE NICHOLAS NWACHUKWU',
    review: 'Ezenwoke Nicholas Nwachukwu was born on the 27th June, 1983. He is an indegene of Abia State from Umuawala Abayi, Isiala Ngwa North Local Government Area. He attended Abayi Group of Schools for his basic Education, he then proceeded to Ntigha Secondary School after which he went to Abia State Polytechnic. He started his football career at Young Stars of Aba as a grassroot Player, then to Pepsi Academy. He then played for Eyimba FEDA Team amongst others. As a Coach, he has Coached at Choices Football Club, Ben Football Club and also CCA Football Club. He is also a grade 2 member of NRA.'
  },
]



const Testimonials = () => {

    
  return (
  <section id='testimonials'>
  <Container>
    <Row>
      <Col>
      <h2>OUR ADMINSTRATIVE TEAM</h2>
    <h2>Statements </h2>
    <Swiper className='container testimonial_container'  // install Swiper modules
      modules={[ Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>

    {
      data.map(({avatar, name, review}, index)=>{
        return(
          <SwiperSlide key={index} className='testimonial'>
    <div className='client_avatar'>
    <img src={avatar} />
    </div>
    <h5 className='client_name'>{name}</h5>
    <small className='client_review'>{review}</small>

    </SwiperSlide>

    
        )
      })
    }

    </Swiper>
      </Col>
    </Row>
  </Container>
    
  </section>
  )
}

export default Testimonials