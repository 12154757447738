import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Company = () => {
  return <section>
    <Container>
    <Row>
        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-twitter-line"></i>twitter</h3>
        </Col>
        

        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-youtube-line"></i>youtube</h3></Col>

        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-facebook-line"></i>facebook</h3></Col>

        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-instagram-line"></i>instagram</h3></Col>

        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-whatsapp-line"></i>whatsapp</h3></Col>

        <Col lg='2' md='3'><h3 className='d-flex align-items-center gap-1'><i class="ri-mail-line"></i>email</h3></Col>

    </Row>
    </Container>
  </section>
}

export default Company;