import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import coz1 from '../../assets/coz1.jpg'
import coz2 from '../../assets/coz2.jpg'
import coz3 from '../../assets/coz3.jpg'
import coz4 from '../../assets/coz4.jpg'
import study1 from '../../assets/study1.jpg'
import study2 from '../../assets/study2.jpg'
import study3 from '../../assets/study3.jpg'
import study4 from '../../assets/study4.png'
import study5 from '../../assets/study5.jpeg'
import './Courses.css'
import CourseCard from './CourseCard'

const coursesData = [
  {
    id: '01',
    title: 'Elite Training',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: study1
  },

  {
    id: '02',
    title: 'Video Analysis',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: study2
  },

  {
    id: '03',
    title: 'Master Classes',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: study3
  },
  {
    id: '04',
    title: 'High Level Competition/Tournament',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: coz1
  },
  {
    id: '05',
    title: 'Invicible Training',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: study4
  },
  {
    id: '06',
    title: 'Football Reports',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: coz2
  },
  {
    id: '07',
    title: 'Sports Nuitrition',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: coz4
  },
  {
    id: '08',
    title: 'Mental Coaching',
    lesson: 12,
    students: 800,
    rating: 1.5,
    imgUrl: study5
  }
]

const Courses = () => {
  return <section id='courses'>
    <Container>
      <Row>

      <Col lg='12' className='mb-5'>
        <div className='course_top d-flex justify-content-between align-items-center'>
        <div className='course_top_left w-50'>
          <h2>Our Popular Courses</h2>
          <p></p>
        </div>

          <div className='w-50 text-end'>
          <button className='btn'>See All</button>
          </div>
        </div>
      </Col>

      {
        coursesData.map(item=>(
          <Col lg='4' md='6'>
          <CourseCard key={item.id} item={item}/>
        </Col>
        ))} 
      </Row>
    </Container>
  </section>
}

export default Courses