

import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import coz7 from '../../assets/coz7.jpg'
import coz6 from '../../assets/coz6.jpg'
import coz5 from '../../assets/coz5.jpg'
import study1 from '../../assets/study1.jpg'

import './free-course.css'
import FreeCourseCard from './FreeCourseCard'

const FreeCourseData = [
    {
        id:'01',
        title: 'Monday: Fitness',
        imgUrl: study1,
        students: 784,
        ratings: 1.2,
    },
    {
        id:'02',
        title: 'Wednesday: Workout & Ball Work',
        imgUrl: coz7,
        students: 784,
        ratings: 1.2,
    },
    {
        id:'03',
        title: 'Thursday: Fitness & Ball Work',
        imgUrl: coz5,
        students: 784,
        ratings: 1.2,
    },
    {
        id:'04',
        title: 'Weekend: Matches',
        imgUrl: coz6,
        students: 784,
        ratings: 1.2,
    },
]

const FreeCourse = () => {
  return <section>
    <Container>
        <Row>
            <Col lg='12' className='text_center mb-5'>
                <h2 className='fw-bold'>Training Schedules</h2>
            </Col>

            {
                FreeCourseData.map(item=>(
                    <Col lg='3' key={item.id}> 
                    <FreeCourseCard item={item}/>
                    </Col>
                ))
            }

            <h2>FOOTBALL METHODOLOGY</h2>
            <p>Jordan Football Academy shall adopt the F.A.S.T Methodology, which stands for
Football Academy system Training in developing its academic modules and training
curriculum. This would involve the team overseeing the technical planning of every
single drill implemented on and off the field. Throughout each academic session,

methodology is divided into various customized long term plans composed of
specific drills for each determined objective within the game model chosen. The
program takes priority in the conventional education of all players allowing them to
reach their full potential on and off field.</p>
            
        </Row>
    </Container>
  </section>
}

export default FreeCourse