import React, { Fragment } from "react";
import Header from "../components/Header/header";
import HeroSection from "../components/Hero-Section/HeroSection";
import Company from "../components/Company-section/Company";
import AboutUs from "../components/About-us/AboutUs";
import Courses from "../components/Courses-section/Courses";
import ChoseUs from "../components/Chose-us/ChoseUs";
import Features from "../components/Feature-section/Features";
import FreeCourse from "../components/Free-courses-section/FreeCourse";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";

const Home = () => {
    return (
    <Fragment>
        <Header />
        <HeroSection />
        <Company />
        <AboutUs />
        <Courses /> 
        <ChoseUs />
        <Features />
        <FreeCourse />
        <Testimonials />
        <Footer />

    </Fragment>
    );
   
}

export default Home;