import React from 'react'
import './about.css'
import { Container, Row, Col } from 'reactstrap'
import Play from '../../assets/Play.jpg'


const AboutUs = () => {
  return <section id='about'>
    <Container>
    <Row>
        <Col lg='6' md='6'>
            <div className='about_img'>
                <img src={Play} alt='' className='w-100'/>
            </div>
        </Col>

        <Col lg='6' md='6'>
            <div className='about_content'>
                <h2>ABOUT US</h2>
                <p>
                JORDAN FOOTBALL ACADEMY was established on JUNE 1st , 2023.And the Academy is fully registered with the Corporate Affairs Commission (CAC) RC number 6996734. JFA is also registered with Abia state Football Association and also affiliated to the Nigeria Football Federation. the Academy created in response to parents needs for their children to participate actively in sporting activities and become professionals who will represent the National team from different age grades, competitions and play for top football clubs globally. 

The academy has the sole and authorization to represent the academy throughout the world in conjunction with football activities and to showcase players profile and performance by all good means including merchandising, sponsorship, endorsement and other advertising or promotional means.

JORDAN FOOTBALL ACADEMY is seeking for sponsorship from the Government, Financial institutions, private sectors, corporate organizations, media outlets and individuals.

                </p>
                <div className='about_counter'>
                <h2>BENEFITS OF JOINING JORDAN FOOTBALL ACADEMY.</h2>
                <p>
                <li>Players will have the opportunity of playing in local and international football competitions</li>
                <li>players will have the opportunity to serve their nation in different age grade competitions at the national level and also go beyond to actualize their dreams in the world of soccer.</li>
                <li>members of JORDAN FOOTBALL ACADEMY profile will be made available to football clubs, football scouts, and football agents all around the world.</li>
                <li>there shall be capacity building training/workshops to enable young stars go for trials both home and abroad to broaden their skills in the game of soccer.</li>
                </p>
                <h2>OUR VISION</h2>
                <p>
                Our vision at Jordan Football Academy is to be the best football and sports development academy in
Nigeria, focusing on holistic growth of youths through a systematic education and
skills development program.

                </p>
                </div>

                <h2>OUR MISSION</h2>
                <p>
                Our mission is inculcating valuable life skills that will equip youths and prepare them
to be the best at the game of football and live out their dream.

                </p>

            </div>
            
        </Col>
        </Row>

    </Container>
  </section>
}

export default AboutUs