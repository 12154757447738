

import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import Play from '../../assets/Play.jpg'
import './chose-us.css'
import ReactPlayer from 'react-player'


const ChoseUs = () => {

    const [showVideo, setShowVideo] = useState(false)

  return <section>
    <Container>
        <Row>
            <Col lg='6'>
                <div className='choose_content'>
                    <h2>THE DREAM</h2>
                    <p>Jordan Football Academy looks forward to setting up a state of the art academy that
will offer world class training facilities for the optimum development of our players.
The academy will have an anonymous faculty for both conventional and sports
curricula. It is a great platform for the players to experience top class football through
league competition and an opportunity for national team selection or international
features.</p>
                </div>
            </Col>
            <Col lg='6'>
                <div className='choose_image'>

                {
                    showVideo ? (
                    <ReactPlayer 
                    url='https://youtu.be/XL3tSVv56kY' 
                    controls 
                    width='100%' 
                    height='300px'/> 
                    )
                    
                    : ( <img src={Play} alt='' className='w-100'/>
                )}

                {
                    !showVideo && <span className='play_icon'>
                    <i class="ri-play-circle-line" onClick={()=>setShowVideo(!showVideo)}></i>
                </span>
                }

                </div>
            </Col>
        </Row>
    </Container>
  </section>
}
export default ChoseUs;
