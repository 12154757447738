

import React from 'react'

const FreeCourseCard = (props) => {


    const {imgUrl, title, desc, students, ratings} = props.item
  return <div className='single_free_courses'>
  <div className='free_course_img mb-5'>
  <img src={imgUrl} alt='' className='w-100'/>
      <button className='btn free_btn'>
          Free
      </button>
  </div>

  <div className='free_course_details'>
  <h6>{title}</h6>
  <div className='d-flex alig-items-center gap-5'>
      <span className='d-flex alig-items-center gap-2'><i class="ri-user-line">{students}</i></span>

      <span className='d-flex alig-items-center gap-2' ><i class="ri-star-fill">{ratings}k</i></span>
  </div>

  </div>

  

  </div>
  
}

<h6>FOOTBALL METHODOLOGY</h6>

export default FreeCourseCard;