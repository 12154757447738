

import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'

const footerQuickLinks = [
    {
        display: 'Home',
        url: '#home',
    },
    {
        display: 'About Us',
        url: '#about',
    },
    {
        display: 'Courses',
        url: '#course',
    },
    {
        display: 'Statements',
        url: '#testimonials',
    },
    {
        display: 'Contact us',
        url: '#footer',
    },

]


const footerInfoLinks = [
    {
        display: 'Privacy policy',
        url: '#',
    },
    {
        display: 'Membership',
        url: '#',
    },
    {
        display: 'Registration Guide',
        url: '#',
    },
    {
        display: 'Terms of Service',
        url: '#',
    },

]


const Footer = () => {
  return <footer className='footer' id='footer'>
    <Container>
        <Row>
            <Col lg='3'>
            <h2 className='d-flex align-items-center gap-1'><i class="ri-pantone-line"></i>Learners.</h2>
            <div className='follows'></div>
            <p className='mb-0'>Follow us on Social Media</p>
            <span>
            {" "}
            <a href='facebook.com'>
            <i class="ri-facebook-line"></i>\
            </a>
            </span>

            <span>
            {" "}
            <a href='facebook.com'>
            <i class="ri-instagram-line"></i>\
            </a>
            </span>

            <span>
            {" "}
            <a href='facebook.com'>
            <i class="ri-linkedin-line"></i>\
            </a>
            </span>

            <span>
            {" "}
            <a href='facebook.com'>
            <i class="ri-twitter-line"></i>\
            </a>
            </span>

            </Col>

            <Col lg='3'>
            <h6 className='fw-bold'>Explore</h6>
                <ListGroup className='link_list'>
                    {
                        footerQuickLinks.map((item, index)=>(
                        <ListGroupItem key={index} className='border-0 ps-0 link_item'>
                        {" "}
                        <a href={item.url}>{item.display}</a>
                        </ListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>

            <Col lg='3'>
            <h6 className='fw-bold'>Information</h6>
                <ListGroup className='link_list'>
                    {
                        footerInfoLinks.map((item, index)=>(
                        <ListGroupItem key={index} className='border-0 ps-0 link_item'>
                        {" "}
                        <a href={item.url}>{item.display}</a>
                        </ListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>

            <Col lg='3'>
                    <h6 className='fw-bold'>Get in Touch</h6>
                    <p>Address: no 4 jambutu park jimeta yola</p>
                    <p>Phone Number:+234 704 058 3225</p>
                    <p>Email: hazilanfootballacademy@gmail.com</p>
            </Col>
        </Row>
    </Container>
  </footer>
}

export default Footer