import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './Features.css'


const FeatureData = [
    {
        title: 'ELIGIBILITY',
        desc:  'The Academy is open to both boys and girls of ages 10 - 22 divided into 3 age categories ' ,
        icon: 'ri-draft-line'
    },
    {
        title: 'ADMISSIONS',
        desc:  'We shall enroll a limited number of athletes every academic session, so to encourage interested student to obtain, fill and submit aplication as early as possible in order to keep pace with normal economic activities',
        icon: 'ri-discuss-line'
    },
    {
        title: 'Certification',
        desc:  'Certification is part of Testing, inspection and certification and the provision by an independent body of written assurance (a certificate) that the product, service or system in question meets specific requirements.[1] It is the formal attestation or confirmation of certain characteristics of an object, person, or organization.',
        icon: 'ri-contacts-book-line'
    },
    
]

const Features = () => {
  return <section>
    <Container>
        <Row>

        {
            FeatureData.map((item, index)=> (<Col lg='4' md='6' key={index}>
            <div className='single_feature px-4'>
                <h2 className='mb-3'>
                <i class={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
            <ul><li><p>{item.desc} </p></li></ul>
            
            </div>

            </Col>))
        }
        

            
        </Row>
    </Container>
  </section>
}

export default Features