import React from "react";
import { Container, Row, Col } from "reactstrap";
import Logo from '../../assets/log.jpg'
import './hero-section.css';

const HeroSection = () => {
    return ( <section id="home">
        <Container>
            <Row>
                <Col lg="6" md="6">
                
            <div className="hero_content">
            <h2 className="mb-4">Self discipline, Integrity, Commitment, Goal oriented, Persistence, Perseverance, Interdependence <br/> giveback.</h2>
            <p className="mb-4">JORDAN FOOTBALL ACADEMY is a grassroots club designed to tap into the power of
football to help Unearth, nurture and develop young talents across the country.
Nigeria has a virile youth population with so much potential untapped due to several
challenges that has caused nationwide disorientation and loss of purpose.</p>
            <div className="search">
                <input type="text" placeholder="Search"/>
                <button className="btn">Search</button>
            </div>
            </div>
                </Col>

                <Col lg="6" md="6">
                <img src={Logo} alt="" className="w-100" />

                </Col>
            </Row>
        </Container>
    </section>
    )
}

export default HeroSection;